@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon {
  font-size: 2rem;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
}

.btn {
  padding: 0.6rem 1.5rem;
  background: #111d5e;
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn a {
  color: white;
  font-weight: 500;
}

.btn:hover {
  background: orange;
}

/* img,
video {
  width: 100%;
  height: auto;
} */

input {
  font-size: 100%;
}

body {
  background: gray;
}
