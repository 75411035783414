@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@300&family=Rubik:wght@400;500;700&display=swap);

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon {
  font-size: 2rem;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
}

.btn {
  padding: 0.6rem 1.5rem;
  background: #111d5e;
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn a {
  color: white;
  font-weight: 500;
}

.btn:hover {
  background: orange;
}

/* img,
video {
  width: 100%;
  height: auto;
} */

input {
  font-size: 100%;
}

body {
  background: gray;
}

img {
  width: 150px;
}
.navBar {
  margin-left: 40px;
}

.navbar-right {
  position: absolute;
  right: 60px;
}

.navItem {
  margin-left: 10px;
  margin-right: 10px;
}
a {
  color: black;
  padding: 10px 10px;
}
.navItem a:hover {
  padding: 10px 10px;
  background-color: #111d5e;
  border-radius: 10px;
  color: white;
}

.icons {
  position: absolute;
  right: 0px;
  display: flex;
}

.header_search {
  right: 70px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  position: absolute;
}
.header_searchInput {
  width: 100%;
  padding: 5px;
  border: 4px solid #111d5e;
  border-radius: 10px 0 0 10px;
  outline: none;
  color: tomato;
  background: none;
  border-right: none;
}
.header_searchIcon {
  margin-left: -3px;
  padding: 5px;
  border-radius: 0 10px 10px 0;
  background-color: #cd9042;
  height: 42px !important;
  border: 4px solid #111d5e;
  width: 40px;
}
.header_userIcon {
  font-size: 20px;
  margin-right: 10px;
}

.logo {
  cursor: pointer;
}

.header {
  background-color: white;
  z-index: 1000;
  padding: 1rem;
}

.burgerNavbar {
  visibility: hidden;
}

.closeNavbar {
  visibility: hidden;
}
.navBar {
  display: flex;
}

@media screen and (max-width: 768px) {
  .navBar {
    position: absolute;
    background: #ffffff;
    height: -webkit-max-content;
    height: max-content;
    width: 60%;
    border-radius: 1rem;
    top: 8rem;
    left: 60%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 2000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
    transition: 3s ease-in-out;

    flex-direction: column;
  }

  .navLists {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 5rem 0;
  }
  .navItem {
    margin-bottom: 15px;
  }
  .closeNavbar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    visibility: visible;
  }
  .burgerNavbar {
    margin-left: 380px;
    visibility: visible;
    position: absolute;
    display: flex;
  }

  .header_userIcon {
    visibility: hidden;
  }

  .navBar_1 {
    position: absolute;
    background: #ffffff;
    height: -webkit-max-content;
    height: max-content;
    width: 60%;
    border-radius: 1rem;
    top: 8rem;
    left: 60%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 2000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
    transition: 3s ease-in-out;

    flex-direction: column;
    visibility: hidden;
  }
  .closeNavbar_1 {
    visibility: hidden;
  }
  .header_searchInput {
    visibility: hidden;
  }
  .header_searchIcon {
    visibility: hidden;
  }
}

.sub-nav {
  border: 1px solid #ccc;
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 5px 5px;
  list-style: none;
  width: 230px;
}
@media screen and (max-width: 768px) {
  .sub-nav {
    position: relative;
    width: 100%;
    display: none;
    background-color: rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
  }
}

.nav__link:hover + .sub-nav {
  display: block;
}

.sub-nav:hover {
  display: block;
}

video {
  width: 100%;
  height: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;
  color: #111;
  background: #f5f6f8;
}

a,
button {
  color: #4fc08d;
}

main {
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: min(20vh, 5em);
}
main .header {
  margin-bottom: 55px;
}
@media screen and (max-width: 600px) {
  main .header {
    margin-bottom: 70px !important;
  }
}
main .header h1 {
  font-weight: 800;
  font-size: 3rem;
  margin: 5px -5px 15px;
}
main .header span {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #471aa0;
}
main .header p {
  max-width: min(40ch, 100% - 2rem);
  line-height: 1.6;
  color: #565656;
}
main .item {
  width: 100%;
  height: 400px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}
main .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}
main .item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 28px 25px;
  color: #fff;
  background: #020024;
  background: linear-gradient(0deg, #020024 0%, #000032a1 3%, #17d9ff00 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
main .item .overlay span {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 60px;
  padding: 10px 20px;
  display: inline-block;
}

.owl-nav {
  position: absolute;
  top: -100px;
  right: 0;
}
@media screen and (max-width: 600px) {
  .owl-nav {
    top: -60px !important;
  }
}
.owl-nav button {
  background: #fff !important;
  width: 45px;
  height: 45px;
  font-size: 25px !important;
  box-shadow: 0px 0px 17px #00000005;
  border-radius: 50%;
}
.owl-nav button:nth-of-type(1) {
  margin-right: 13px;
}
.owl-carousel {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
img {
  /* width: 50px !important; */
  object-fit: contain !important;
}

.main_list li:before {
  color: black; /* bullet colour */
  content: "\2022"; /* this will show • */
  font-size: 1.5em; /* use ems or % */
  padding-right: 0.25em; /* add padding to bullet (ems) */
  position: relative;
  top: 0.1em; /* set the vertical position of the bullet. Use em or % */
}
li {
  margin-left: 10px;
  margin-top: 10px;
  font-weight: 400;
}
.main_list {
  margin-top: 30px;
}
.item-content {
  height: 170px !important;
  overflow: auto;
}

/* scrollspy */

:root {
  --space: 1rem;
  --border: 4px;
  --page-width: 80ch;
  --font-family: "Helvetica", sans-serif;
  --color-link: black;
  --color-active: royalblue;
  --ease: cubic-bezier(0.23, 1, 0.32, 1);
  --duration: 350ms;
  --section-offset: 0;
}

* {
  box-sizing: border-box;
}

html {
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
  scroll-behavior: var(--scroll-behavior);
}
@media (prefers-reduced-motion: reduce) {
  html {
    --scroll-behavior: auto;
  }
}

body {
  font-family: "Helvetica", sans-serif;
  font-family: var(--font-family);
  line-height: 1.5;
}

h1,
h2,
h3 {
  font-weight: bold;
  line-height: 1.25;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1.5rem;
}

ul:not(.menu) {
  list-style-type: disc;
  margin-left: 1rem;
  margin-left: var(--space);
}
ul:not(.menu) > li + li {
  margin-top: 1rem;
  margin-top: var(--space);
}

.nav-sections {
  position: -webkit-sticky;
  position: sticky;
  margin-top: 50px;
  width: 100%;
  background-color: white;
  box-shadow: inset lightgray 0 -1px 0, rgba(0, 0, 0, 0.15) 0 3px 10px 0;
  z-index: 100;
}
.nav-sections .menu {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  overscroll-behavior: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 0 auto;
  max-width: 80ch;
  max-width: var(--page-width);
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: -webkit-transform cubic-bezier(0.23, 1, 0.32, 1) 350ms;
  transition: transform cubic-bezier(0.23, 1, 0.32, 1) 350ms;
  transition: transform cubic-bezier(0.23, 1, 0.32, 1) 350ms, -webkit-transform cubic-bezier(0.23, 1, 0.32, 1) 350ms;
  transition: -webkit-transform var(--ease) var(--duration);
  transition: transform var(--ease) var(--duration);
  transition: transform var(--ease) var(--duration), -webkit-transform var(--ease) var(--duration);
}
.nav-sections .menu::-webkit-scrollbar {
  display: none;
}
.nav-sections .menu-item-link {
  display: block;
  padding: calc(1rem * 1.5) 1rem;
  padding: calc(var(--space) * 1.5) var(--space);
  text-decoration: none;
  white-space: nowrap;
  color: black;
  color: var(--color-link);
  transition: color cubic-bezier(0.23, 1, 0.32, 1) 350ms;
  transition: color var(--ease) var(--duration);
}
.nav-sections .menu-item-link.active {
  color: royalblue;
  color: var(--color-active);
}

.active-line {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: royalblue;
  background-color: var(--color-active);
  transition: width cubic-bezier(0.23, 1, 0.32, 1) 350ms,
    -webkit-transform cubic-bezier(0.23, 1, 0.32, 1) 350ms;
  transition: width cubic-bezier(0.23, 1, 0.32, 1) 350ms,
    transform cubic-bezier(0.23, 1, 0.32, 1) 350ms;
  transition: width cubic-bezier(0.23, 1, 0.32, 1) 350ms,
    transform cubic-bezier(0.23, 1, 0.32, 1) 350ms,
    -webkit-transform cubic-bezier(0.23, 1, 0.32, 1) 350ms;
  transition: width var(--ease) var(--duration),
    -webkit-transform var(--ease) var(--duration);
  transition: width var(--ease) var(--duration),
    transform var(--ease) var(--duration);
  transition: width var(--ease) var(--duration),
    transform var(--ease) var(--duration),
    -webkit-transform var(--ease) var(--duration);
}

.placeholder-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50vh;
  background-color: lightgray;
}

.page-sections {
  padding: 20px;
}

.page-section {
  margin: calc(1rem * 2) 0;
  margin: calc(var(--space) * 2) 0;
  padding: calc(1rem * 2) 1rem;
  padding: calc(var(--space) * 2) var(--space);
}

.section-title {
  outline: none;
  margin-bottom: 40px;
  font-size: 50px;
  text-align: center;
}
.section-title:before {
  content: "";
  display: block;
  visibility: hidden;
  pointer-events: none;
  margin-top: calc(0 * -1px);
  margin-top: calc(var(--section-offset) * -1px);
  height: calc(0 * 1px);
  height: calc(var(--section-offset) * 1px);
}
.main-spec {
  display: flex;
  flex-direction: column;
}
.detail {
  display: flex;
  flex-direction: row;
}
.spec {
  margin-top: 30px;

  width: 100%;
}
.text-style {
  margin-top: 20px;
  font-weight: 600;
}

/* resources */

.tabbordion {
  display: flex;
  flex-wrap: wrap;
}
.tabbordion > input[type="radio"] {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.tabbordion > section {
  display: none;
}
.tabbordion > input[type="radio"]:checked + label + section {
  display: unset;
}
.tabbordion > section {
  order: 9999;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .tabbordion {
    flex-direction: column;
  }
  .tabbordion > section {
    order: unset;
    width: unset;
  }
}
.tabbordion > label {
  padding: 1em 2em;
  background: #cfd1d0;
}
.tabbordion > input[type="radio"]:checked + label {
  font-weight: bold;
  background: #a9d4b4;
}
.tabbordion > section {
  border-top: 2px solid green;
}
.rs-1 {
  font-size: 24px;
  font-weight: 600;
}
.rs-img {
  width: 400px;
}
.tab-content {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
}
.tab-detail {
  margin-right: 40px;
}
.panel-txt {
  text-align: start;
  font-weight: 600;
}

:root {
  --light: #ffffff;
  --dark: #000;
}
body {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h3 {
  font-family: "Noto Serif Display", serif;
  font-size: 2.3rem;
  font-weight: 300;
  text-align: center;
}
a {
  text-decoration: none;
  color: #000;
  color: var(--dark);
  font-weight: 500;
  transition: 0.3s ease-in;
  border-bottom: 1px solid transparent;
  margin-bottom: 0.5rem;
  display: inline-flex;
}
a:hover {
  border-bottom: 1px solid #000;
  border-bottom: 1px solid var(--dark);
}
ul {
  list-style-type: none;
  padding: 0;
}
button {
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  background: transparent;
}
.flex {
  display: flex;
}
.footer_video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  border: none;
}
.footer_inner {
  background: #ffffff;
  background: var(--light);
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  border: 0.1px solid rgba(233, 232, 232, 0.208);
  border-radius: 5px;
  padding: 2rem;
  margin: 1rem 0;
}
.footer {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: min(100%, 400px);
  border-bottom: 1px solid #000;
  border-bottom: 1px solid var(--dark);
}

input {
  padding: 0.75rem 0;
  border: none;
  background: none;
  font-weight: 500;
  transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 0;
  width: 100%;
  font-size: 1.05rem;
  font-weight: bolder;
}
input:focus {
  outline: none;
}
input::-webkit-input-placeholder {
  color: #000;
  color: var(--dark);
}
input::placeholder {
  color: #000;
  color: var(--dark);
}
@media (min-width: 675px) {
  .layout {
    display: flex;
    flex-wrap: nowrap;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
  .w-50 {
    width: 50%;
  }
  .w-25 {
    width: 25%;
  }
}
form {
  position: relative;
}
svg {
  margin: 0.5rem;
}
.c-2 {
  margin-top: 3.5rem;
}
.footer_copyright {
  color: #ffffff;
  color: var(--light);
}
.sub_icon {
  font-size: 40px;
  margin-left: 220px;
}
.c-nav-tool {
  /* position: absolute; */
  padding-top: 50px;
  right: 50px;
  position: relative;
}

.c-nav-tool_title {
  font-size: 28px;
  margin-bottom: 20px;
}

.env_icon {
  font-size: 40px;
  margin-left: 100px;
}
.tool_icon {
  font-size: 40px;
  margin-left: 50px;
}

.footer_btn {
  margin-top: 50px;

  width: 180px;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: solid 1px black;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  position: absolute;
  /* outline: none; */
}
.footer_btn:hover {
  background-color: #111d5e;
  box-shadow: 0px 15px 20px rgba(11, 113, 243, 0.4);
  color: #fff;
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}
.newsletter {
  padding-top: 50px;
}

