@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;
  color: #111;
  background: #f5f6f8;
}

a,
button {
  color: #4fc08d;
}

main {
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: min(20vh, 5em);
}
main .header {
  margin-bottom: 55px;
}
@media screen and (max-width: 600px) {
  main .header {
    margin-bottom: 70px !important;
  }
}
main .header h1 {
  font-weight: 800;
  font-size: 3rem;
  margin: 5px -5px 15px;
}
main .header span {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #471aa0;
}
main .header p {
  max-width: min(40ch, 100% - 2rem);
  line-height: 1.6;
  color: #565656;
}
main .item {
  width: 100%;
  height: 400px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}
main .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}
main .item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 28px 25px;
  color: #fff;
  background: #020024;
  background: linear-gradient(0deg, #020024 0%, #000032a1 3%, #17d9ff00 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
main .item .overlay span {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 60px;
  padding: 10px 20px;
  display: inline-block;
}

.owl-nav {
  position: absolute;
  top: -100px;
  right: 0;
}
@media screen and (max-width: 600px) {
  .owl-nav {
    top: -60px !important;
  }
}
.owl-nav button {
  background: #fff !important;
  width: 45px;
  height: 45px;
  font-size: 25px !important;
  box-shadow: 0px 0px 17px #00000005;
  border-radius: 50%;
}
.owl-nav button:nth-of-type(1) {
  margin-right: 13px;
}
.owl-carousel {
  display: flex;
  gap: 10px;
}
img {
  /* width: 50px !important; */
  object-fit: contain !important;
}

.main_list li:before {
  color: black; /* bullet colour */
  content: "\2022"; /* this will show • */
  font-size: 1.5em; /* use ems or % */
  padding-right: 0.25em; /* add padding to bullet (ems) */
  position: relative;
  top: 0.1em; /* set the vertical position of the bullet. Use em or % */
}
li {
  margin-left: 10px;
  margin-top: 10px;
  font-weight: 400;
}
.main_list {
  margin-top: 30px;
}
.item-content {
  height: 170px !important;
  overflow: auto;
}

/* scrollspy */

:root {
  --space: 1rem;
  --border: 4px;
  --page-width: 80ch;
  --font-family: "Helvetica", sans-serif;
  --color-link: black;
  --color-active: royalblue;
  --ease: cubic-bezier(0.23, 1, 0.32, 1);
  --duration: 350ms;
  --section-offset: 0;
}

* {
  box-sizing: border-box;
}

html {
  --scroll-behavior: smooth;
  scroll-behavior: var(--scroll-behavior);
}
@media (prefers-reduced-motion: reduce) {
  html {
    --scroll-behavior: auto;
  }
}

body {
  font-family: var(--font-family);
  line-height: 1.5;
}

h1,
h2,
h3 {
  font-weight: bold;
  line-height: 1.25;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1.5rem;
}

ul:not(.menu) {
  list-style-type: disc;
  margin-left: var(--space);
}
ul:not(.menu) > li + li {
  margin-top: var(--space);
}

.nav-sections {
  position: sticky;
  margin-top: 50px;
  width: 100%;
  background-color: white;
  box-shadow: inset lightgray 0 -1px 0, rgba(0, 0, 0, 0.15) 0 3px 10px 0;
  z-index: 100;
}
.nav-sections .menu {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 0 auto;
  max-width: var(--page-width);
  transform: translateZ(0);
  transition: transform var(--ease) var(--duration);
}
.nav-sections .menu::-webkit-scrollbar {
  display: none;
}
.nav-sections .menu-item-link {
  display: block;
  padding: calc(var(--space) * 1.5) var(--space);
  text-decoration: none;
  white-space: nowrap;
  color: var(--color-link);
  transition: color var(--ease) var(--duration);
}
.nav-sections .menu-item-link.active {
  color: var(--color-active);
}

.active-line {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: var(--color-active);
  transition: width var(--ease) var(--duration),
    transform var(--ease) var(--duration);
}

.placeholder-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50vh;
  background-color: lightgray;
}

.page-sections {
  padding: 20px;
}

.page-section {
  margin: calc(var(--space) * 2) 0;
  padding: calc(var(--space) * 2) var(--space);
}

.section-title {
  outline: none;
  margin-bottom: 40px;
  font-size: 50px;
  text-align: center;
}
.section-title:before {
  content: "";
  display: block;
  visibility: hidden;
  pointer-events: none;
  margin-top: calc(var(--section-offset) * -1px);
  height: calc(var(--section-offset) * 1px);
}
.main-spec {
  display: flex;
  flex-direction: column;
}
.detail {
  display: flex;
  flex-direction: row;
}
.spec {
  margin-top: 30px;

  width: 100%;
}
.text-style {
  margin-top: 20px;
  font-weight: 600;
}

/* resources */

.tabbordion {
  display: flex;
  flex-wrap: wrap;
}
.tabbordion > input[type="radio"] {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.tabbordion > section {
  display: none;
}
.tabbordion > input[type="radio"]:checked + label + section {
  display: unset;
}
.tabbordion > section {
  order: 9999;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .tabbordion {
    flex-direction: column;
  }
  .tabbordion > section {
    order: unset;
    width: unset;
  }
}
.tabbordion > label {
  padding: 1em 2em;
  background: #cfd1d0;
}
.tabbordion > input[type="radio"]:checked + label {
  font-weight: bold;
  background: #a9d4b4;
}
.tabbordion > section {
  border-top: 2px solid green;
}
.rs-1 {
  font-size: 24px;
  font-weight: 600;
}
.rs-img {
  width: 400px;
}
.tab-content {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
}
.tab-detail {
  margin-right: 40px;
}
.panel-txt {
  text-align: start;
  font-weight: 600;
}
