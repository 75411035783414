img {
  width: 150px;
}
.navBar {
  margin-left: 40px;
}

.navbar-right {
  position: absolute;
  right: 60px;
}

.navItem {
  margin-left: 10px;
  margin-right: 10px;
}
a {
  color: black;
  padding: 10px 10px;
}
.navItem a:hover {
  padding: 10px 10px;
  background-color: #111d5e;
  border-radius: 10px;
  color: white;
}

.icons {
  position: absolute;
  right: 0px;
  display: flex;
}

.header_search {
  right: 70px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  position: absolute;
}
.header_searchInput {
  width: 100%;
  padding: 5px;
  border: 4px solid #111d5e;
  border-radius: 10px 0 0 10px;
  outline: none;
  color: tomato;
  background: none;
  border-right: none;
}
.header_searchIcon {
  margin-left: -3px;
  padding: 5px;
  border-radius: 0 10px 10px 0;
  background-color: #cd9042;
  height: 42px !important;
  border: 4px solid #111d5e;
  width: 40px;
}
.header_userIcon {
  font-size: 20px;
  margin-right: 10px;
}

.logo {
  cursor: pointer;
}

.header {
  background-color: white;
  z-index: 1000;
  padding: 1rem;
}

.burgerNavbar {
  visibility: hidden;
}

.closeNavbar {
  visibility: hidden;
}
.navBar {
  display: flex;
}

@media screen and (max-width: 768px) {
  .navBar {
    position: absolute;
    background: #ffffff;
    height: max-content;
    width: 60%;
    border-radius: 1rem;
    top: 8rem;
    left: 60%;
    transform: translate(-50%);
    z-index: 2000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
    transition: 3s ease-in-out;

    flex-direction: column;
  }

  .navLists {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 5rem 0;
  }
  .navItem {
    margin-bottom: 15px;
  }
  .closeNavbar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    visibility: visible;
  }
  .burgerNavbar {
    margin-left: 380px;
    visibility: visible;
    position: absolute;
    display: flex;
  }

  .header_userIcon {
    visibility: hidden;
  }

  .navBar_1 {
    position: absolute;
    background: #ffffff;
    height: max-content;
    width: 60%;
    border-radius: 1rem;
    top: 8rem;
    left: 60%;
    transform: translate(-50%);
    z-index: 2000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
    transition: 3s ease-in-out;

    flex-direction: column;
    visibility: hidden;
  }
  .closeNavbar_1 {
    visibility: hidden;
  }
  .header_searchInput {
    visibility: hidden;
  }
  .header_searchIcon {
    visibility: hidden;
  }
}

.sub-nav {
  border: 1px solid #ccc;
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 5px 5px;
  list-style: none;
  width: 230px;
}
@media screen and (max-width: 768px) {
  .sub-nav {
    position: relative;
    width: 100%;
    display: none;
    background-color: rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
  }
}

.nav__link:hover + .sub-nav {
  display: block;
}

.sub-nav:hover {
  display: block;
}
